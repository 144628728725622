import { Component } from 'solid-js'
import { ToggleFieldProps } from '~/components/fields/toggle-field/toggle-field.interfaces'
import styles from '~/components/fields/toggle-field/toggle-field.module.scss'

const ToggleField: Component<ToggleFieldProps> = ({ name, label, error, value, variant, ...props }) => {
  const cssClasses = () => ({
    [styles.toggleField]: true,
    [styles.hasError]: error !== '',
    [styles.large]: variant === 'large'
  })

  return (
    <label classList={cssClasses()}>
      <div class={styles.label}>
        {label}
      </div>
      <input
        id={name}
        class={styles.input}
        type='checkbox'
        checked={value}
        {...props}
      />
    </label>
  )
}

export default ToggleField
